import { SEO } from "components/seo/SEO.component";
import React from "react";
import { Careers as CareersModule } from "../modules/careers/Careers.component";

const Careers = () => {
  return (
    <>
      <SEO title="Careers" />
      <CareersModule />
    </>
  );
};

export default Careers;
