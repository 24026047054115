import React, { useEffect } from "react";
import {
  CAREERS_HEADING,
  CURRENT_OPEN_POSITIONS,
  ENCOURAGEMENT_TO_APPLY,
  PACBAY_FACULTY_HEADING,
  STAFF_POSITIONS,
  TEACHING_POSITIONS,
  facultyCriterion,
  staffPositions,
  teachingPositions,
} from "./constants";
import "./styles.scss";

export const Careers = () => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div className="careers fix-header-overlap">
      <aside className="side-background">
        <div className="overlay" />
        <h2 className="heading invert-color no-margin">{CAREERS_HEADING}</h2>
      </aside>
      <main style={{ padding: "2.5rem", overflowY: "auto" }}>
        <section>
          <div className="side-body">
            <div className="mission-vision">
              <h3>{PACBAY_FACULTY_HEADING}</h3>
              <ul>
                {facultyCriterion.map((criteria) => (
                  <li style={{ margin: "0.5rem" }}>{criteria}</li>
                ))}
              </ul>
              <br />
              <p
                style={{
                  lineHeight: "1.5rem",
                  maxWidth: "600px",
                }}
              >
                {ENCOURAGEMENT_TO_APPLY}
              </p>
              <div display="block" style={{ margin: "2rem 0rem" }}>
                <hr />
              </div>
              <h3>{CURRENT_OPEN_POSITIONS}</h3>
              <h4>{TEACHING_POSITIONS}</h4>
              <ul>
                {teachingPositions.map(({ title, link }) => (
                  <li style={{ margin: "0.5rem" }}>
                    <a target="_blank" href={link}>
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
              <h4>{STAFF_POSITIONS}</h4>
              <ul>
                {staffPositions.map(({ title, link }) => (
                  <li style={{ margin: "0.5rem" }}>
                    <a target="_blank" href={link}>
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
