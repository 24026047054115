export const CAREERS_HEADING =
  "At PacBay, we inspire students to be ambitious, joyful, and deeply engaged leaders in a quickly changing world";

export const PACBAY_FACULTY_HEADING = "PacBay Faculty:";

export const facultyCriterion = [
  "Teaches with expertise and excellence",
  "Practices effective pedagogy",
  "Demonstrates learning with relevant applications",
  "Builds lasting and healthy relationships with each student",
  "Models an integrated life of faith, wisdom, and service",
];

export const ENCOURAGEMENT_TO_APPLY =
  "If this educational philosophy resonates with you, we would love to talk with you!";

export const CURRENT_OPEN_POSITIONS = "Current Open Positions:";
export const TEACHING_POSITIONS = "Teaching Positions:";
export const STAFF_POSITIONS = "Staff Positions:";

export const teachingPositions = [
  {
    title: "High School Math Teacher",
    link: "https://www.edjoin.org/Home/JobPosting/1603336",
  },
  {
    title: "High School Mandarin Teacher & International Student Coordinator",
    link: "https://www.edjoin.org/Home/JobPosting/1913616",
  },
  {
    title: "Middle School Humanities Teacher",
    link: "https://www.edjoin.org/Home/JobPosting/1909967",
  },
];

export const staffPositions = [
  {
    title: "Assistant Athletic Director",
    link: "https://www.edjoin.org/Home/JobPosting/1909980",
  },
];
